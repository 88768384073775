<template>
    <card-table-search :items="sortedEvents" :fields="fields" :actions="actions">
        <template #title>
            Permanences & événements
            <b-button pill variant="success" v-b-tooltip.hover="'Créer un événement'"
                      @click="createEventModal" size="sm" class="ml-2">
                <font-awesome-icon icon="plus"/>
            </b-button>
        </template>
    </card-table-search>
</template>

<script>
    import {apiPath}                  from '@/util/http';
    import {formatDates, momentDates} from "@/util/demande";
    import alert                      from '@/util/alert';
    import {numberSortDesc}           from '@/util/sort';

    const CardTableSearch             = () => import('@/components/CardTableSearch');
    const AdminEditCalendarEventModal = () => import( '@/components/modals/AdminEditCalendarEventModal');

    export default {
        name: "AdminCalendarEventsManual",
        components: {CardTableSearch},
        data() {
            return {
                events: [],
                fields: [
                    {
                        key: 'nom',
                        label: 'Nom',
                        sortable: true,
                        formatter: val => val || 'Permanence'
                    },
                    {
                        key: 'url',
                        label: 'URL',
                        sortable: true
                    },
                    {
                        key: 'dateDebut',
                        label: 'Dates',
                        sortable: true,
                        formatter: (value, key, item) => formatDates(item)
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                actions: [
                    {
                        key: 'action_edit',
                        color: () => 'info',
                        icon: 'pen',
                        tooltip: 'Éditer',
                        handler: ({item}) => this.editEventModal(item)
                    },
                    {
                        key: 'action_delete',
                        color: () => 'danger',
                        icon: 'trash',
                        tooltip: "Supprimer",
                        handler: ({item}) => this.deleteEvent(item)
                    }
                ],
            }
        },
        computed: {
            sortedEvents() {
                return this.events.sort((a, b) => numberSortDesc(a.dateDebut, b.dateDebut));
            }
        },
        methods: {
            loadData() {
                alert.loading();
                this.axios.get(apiPath('list_calendar_events'))
                    .then(response => this.events = momentDates(response.data))
                    .catch(() => this.$toaster.error('Impossible de charger la liste des événements'))
                    .finally(alert.stopLoading);
            },
            createEventModal() {
                this.$store.dispatch('modal/create', {
                    component: AdminEditCalendarEventModal,
                    props: {callback: () => this.loadData()}
                });
            },
            editEventModal(event) {
                this.$store.dispatch('modal/create', {
                    component: AdminEditCalendarEventModal,
                    props: {event: event, callback: () => this.loadData()}
                });
            },
            deleteEvent(event) {
                alert.confirm('Supprimer cet événement ?', () => {
                    this.axios.delete(apiPath('admin_delete_calendar_event', {event: event.id}))
                        .then(() => {
                            this.$toaster.success('Événement supprimé');
                            return this.loadData();
                        })
                        .catch(() => this.$toaster.error("Impossible de supprimer l'événement"));
                })
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>
